import * as React from 'react';
import styled from 'styled-components';
import { motion } from 'framer-motion';
import Layout from '@components/layout';
import EndMark from '@images-svg/endmark.svg';
import StartMark from '@images-svg/startmark.svg';
import SubtleBg from '@components/subtlebg';
import BGTeollisuus from '@components/bgteollisuus';
import TheEnd from '@components/theend';
import NarratorImg from '@images/narrator.png';
import { FooterInnerPage } from '@components/footer';
import { Link } from 'gatsby';
import Seo from '@components/seo';
import Content from '@common/content';

const REFERENCE = {
  meta: {
    referenceTitle: 'Teollisuus',
    highlights: [
      'B2B-kaupan suuri volyymi',
      'Toimittajaketjujen informaatiotulva',
      'Tuotantohallien ja tehtaiden toimintaperiaatteiden ymmärtäminen',
      'Suurten projektikokonaisuuksien hallinta',
      'Fyysisten esineiden valmistaminen - muutoksia voi tehdä koneistamiseen asti'
    ]
  },
  discussion: [
    {
      text: 'Hei! Kuka olet ja mikä on tyypillinen työnkuvasi?'
    },
    {
      nick: 'Tuomas',
      className: 'light right',
      text: 'Sovellusarkkitehti, konsultoin, suunnittelen ja toteutan räätälöityjä sovelluksia alusta loppuun. Yleensä vuosia kestäviä projekteja.'
    },
    {
      nick: 'Matti',
      className: 'cyan right',
      text: 'Olen Matti, 45-vuotias ohjelmoinnin käsityöläinen, ja tyypillinen työpäivä kuluu ongelmanratkaisun parissa. Mahtuu päivään yleensä muutama palaverikin, jossa ratkotaan ongelmia yhdessä, tai suunnitellaan mitkä ne seuraavat ratkaistavat haasteet ovat.'
    },
    //---
    {
      text: 'Miten päädyit ohjelmistoalalle?'
    },
    {
      nick: 'Matti',
      className: 'cyan right',
      text: 'Aloittelin Commodore 64 Basic-ohjelmilla ja jotain pientä MikroMikko PC:llä 90-luvulla. Unohdin muutamaksi vuodeksi kuinka mukavaa se koodaus olikaan ja kokeilin muutamaa muuta opiskelusuuntaa ennenkuin lopulta 2002 siirryin Jyväskylän AMK:n koodauslinjalle. Sillä tiellä edelleen.'
    },
    {
      nick: 'Tuomas',
      className: 'light right',
      text: 'Ala-asteelta lähtien olen puuhannut tietokoneiden kanssa. Päätin jo pienenä, että haluan tehdä tätä työkseni. Jyväskylän AMK:n ohjelmointilinjalle lopuilta päädyin 2003 armeijan jälkeen ja sillä tiellä ollaan.'
    },
    //---
    {
      text: 'Mitä toimialalla halutaan saavuttaa IT hankkeilla?'
    },
    {
      nick: 'Matti',
      className: 'cyan right',
      text: 'Samaa kuin muilla aloilla, eli prosessien optimointia ja/tai työn teon helpottamista käyttäjille ja sitä kautta kokonaisvaltaisia säästöjä.'
    },
    //---
    {
      text: 'Miten IT-hankkeet lähtevät käyntiin?'
    },
    {
      nick: 'Tuomas',
      className: 'light right',
      text: 'Keksitään joku idea joka säästää rahaa sekä helpottaa ihmisten työtä.'
    },
    {
      nick: 'Matti',
      className: 'cyan right',
      text: 'kuten Tuomas sanoi. Sen jälkeen lähdetään yhdessä asiakkaan (mielellään myös loppukäyttäjän) kanssa etenemään asiakkaan toivomalla tavalla. Monesti pyritään alun yhteisymmärryksen hakemisen jälkeen aloittamaan pocin kautta mlp:hen (most lovable product) jolla päästään suunnittelussa eteenpäin ja viimeistelemään ominaisuudet.'
    },
    //---
    {
      text: 'Mitkä ovat tyypillisiä haasteita teollisuuden toimialalla?'
    },
    {
      nick: 'Tuomas',
      className: 'light right',
      text: 'Yritysten business domain on yleensä erittäin monimutkainen ja sen sisäistäminen kestää useita vuosia.'
    },
    //---
    {
      text: 'Mikä on palkitsevinta teollisuuden projekteissa?'
    },
    {
      nick: 'Tuomas',
      className: 'light right',
      text: '1. Tuotantoon asti asioiden saaminen. 2. Käytännön näkeminen, kuinka rahaa ja aikaa alkaa säästymään. 3. Loppukäyttäjäpalaute, jos sovellukset ovat onnistuneita.'
    },
    //---
    {
      text: 'Kuvaile Taitopilven lähestymistapaa?'
    },
    {
      nick: 'Tuomas',
      className: 'light right',
      text: 'Taitopilvenä pyrimme toimimaan samalla puolella pöytää asiakkaan kanssa. Pätee kaikkiin asioihin.'
    },
    //---
    {
      text: 'Millaisia onnistuneita projekteja olette tehneet teollisuuteen?'
    },
    {
      nick: 'Matti',
      className: 'cyan right',
      text: 'Viime vuosina on tullut tehtyä paljon sovellusten siirtämistä kevyemmille alustoille (ja/tai pilveen). Tällä on saavutettu säästöjä sekä operointikustannuksissa, että lisenssikustannuksissa.'
    },
    //---
    {
      text: 'Mikä on ollut haastavinta teollisuuden projekteissa?'
    },
    {
      nick: 'Matti',
      className: 'cyan right',
      text: 'Kokonaiskuvan hahmottaminen ja sieltä olennaisten, tärkeimpien asioiden löytäminen on aina haastavaa. Parasta on, jos tässä onnistutaan asiakkaan kanssa yhdessä ja opitaan matkan varrella.'
    },
    //---
    {
      text: 'Erikoisin asiakasvaatimus?'
    },
    {
      nick: 'Tuomas',
      className: 'light right',
      text: 'Pyyntö, että nyt lähdet välillä käymään myös kotona.'
    },
    //---
    {
      text: 'Kuinka valitset teknologiat?'
    },
    {
      nick: 'Tuomas',
      className: 'light right',
      text: 'Jokaisen uuden toiminnon aloituksessa tulee validoitua sen hetkinen teknologia stäkki ja parhaimmalla ja uusimmalla tavalla pyritään aina toteuttamaan lopputulos.'
    },
    //---
    {
      text: 'Millaisia innovatiivisia ratkaisuja olette keksineet?'
    },
    {
      nick: 'Matti',
      className: 'cyan right',
      text: 'Ennen kuin oli kaupallisia vaihtoehtoja, teimme näppärän mikropalveluiden suorituskykyä mittaavan palikan, joka näytti requestissa kulkevan correlationId:n perusteella koko suoritusketjun läpi sekä sovelluksen, että tietokannan suoritusajat ja mahdolliset virheet. Nykyäänhän siihen on myös valmiita ratkaisuja olemassa.'
    },
    //---
    {
      text: 'Miten asiakasorganisaation ulkopuolinen toimija voi parantaa yhteistyötä eri stakeholderien välillä?'
    },
    {
      nick: 'Matti',
      className: 'cyan right',
      text: 'Olemalla joustava, työskentelemällä sekä asiakkaan kanssa, että muiden toimittajien kanssa avoimesti keskustellen.'
    },
    //---
    {
      text: 'Mitä olet oppinut ja miten tätä voi hyödyntää tulevaisuudessa?'
    },
    {
      nick: 'Tuomas',
      className: 'light right',
      text: 'Käytännössä joka päivä oppii jotain uutta. Niitä asioita pystyy hyödyntämään aina tulevaisuudessa. Sanotaan myös toiselta nimeltä kokemukseksi.'
    },
    //---
    {
      text: 'Miten pysyt ajan tasalla trendeissä?'
    },
    {
      nick: 'Tuomas',
      className: 'light right',
      text: 'Käyn alan konfferensseissa ja tutkin aina uusimpia teknologioita. Uusimmat teknologiat otetaan käyttöön, kun siihen on mahdollisuus.'
    },
    //---
    {
      text: 'Mihin uskot alan kehittyvän?'
    },
    {
      nick: 'Tuomas',
      className: 'light right',
      text: 'Asiat automatisoituu entistä enemmän. Sille ei varmaan loppua minun elämän aikana tule.'
    },
    //---
    {
      text: 'Mitä vinkkejä antaisit teollisuuden toimialalla työskenteleville henkilöille?'
    },
    {
      nick: 'Tuomas',
      className: 'light right',
      text: 'Liiketoimintaprosesseihin kannattaa perehtyä. Massiivisten projektien projektin hallinta on haastava kokonaisuus, jonka oppii käytännön kautta.'
    },
    //---
    {
      text: 'Jos saisit tehdä ohjelmistoprojektia kenen tahansa fiktiivisenkin henkilön kanssa, niin kuka se olisi?'
    },
    {
      nick: 'Tuomas',
      className: 'light right',
      text: 'Egyptin pyramidi projektien suunnittelijoiden kanssa.'
    },
    //---
    {
      text: 'Mottosi?'
    },
    {
      nick: 'Matti',
      className: 'cyan right',
      text: 'Eipäs hätäillä, kyllä tämäkin järjestyy'
    },
    {
      nick: 'Tuomas',
      className: 'light right',
      text: 'Asiakkaan asialla.'
    }
  ]
};

const Kauppa = () => (
  <Layout hasHeroArea={false} isAvailableInEnglish={false}>
    <Seo title="Teollisuus" description="Taitopilveläiset Matti ja Tuomas vastaavat kysymyksiin" />
    <SubPageBackground>
      <GradientBg>
        <Content>
          <SubpageNav>
            <p className="text-2xl uppercase mt-10 mb-0 font-condensed">
              <Link to="/#asiakascaset">Asiakascaset</Link>
            </p>
            <h2>☛ {REFERENCE.meta.referenceTitle}</h2>
            <p className="italic font-light">Taitopilveläiset Matti ja Tuomas vastaavat kysymyksiin</p>
          </SubpageNav>
          <BGTeollisuus>
            <fieldset>
              <legend>Toimialan erityispiirteet</legend>
              <ul>
                {REFERENCE.meta.highlights.map((highlight, i) => (
                  <li key={i}>{highlight}</li>
                ))}
              </ul>
            </fieldset>
          </BGTeollisuus>
          <StyledStartMark />
          <SubtleBg>
            <Discussion>
              {REFERENCE.discussion.map((speechBubble, i) => (
                <SpeechBubble
                  key={i}
                  initial={{ opacity: 0 }}
                  whileInView={{ opacity: 1 }}
                  className={speechBubble.className ? speechBubble.className : 'narrator'}
                >
                  {!speechBubble.className && <img src={NarratorImg} alt="Kysyjä" />}
                  <p>{speechBubble.text}</p>
                  {speechBubble.nick && <p className="nick">{speechBubble.nick}</p>}
                </SpeechBubble>
              ))}
            </Discussion>
          </SubtleBg>
          <StyledEndMark />
          <CrossRef>
            <Link className="button" to="/#asiakascaset">
              Muut asiakascaset
            </Link>
          </CrossRef>
        </Content>
      </GradientBg>
      <FooterInnerPage />
      <TheEnd />
    </SubPageBackground>
  </Layout>
);

const GradientBg = styled.div`
  && {
  }
`;

const SubPageBackground = styled.section`
  && {
    background: var(--t-gray-dark-2);
    color: var(--t-white);
  }
`;

const SubpageNav = styled.nav`
  && {
    padding: 1em;
    @media (min-width: 35em) {
      padding: 2em;
    }
    a {
      color: var(--t-white);
      text-decoration: none;
      &:hover {
        text-decoration: underline;
      }
    }
  }
`;

const CrossRef = styled.nav`
  && {
    padding-bottom: 3em;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    a {
      padding: 0.5em;
      text-shadow: 1px 1px 3px black;
      color: var(--t-white);
      text-decoration: none;
      cursor: pointer;
    }
    h3 {
      padding: 0.75em 2em;
    }
  }
`;

const Discussion = styled.article`
  && {
    display: flex;
    flex-direction: column;
    padding: 0.25em 0.5em;
    border: 8px double var(--t-gray-dark-2);
    @media (min-width: 35em) {
      padding: 1.5em 2em;
    }
  }
`;

const CYAN = `var(--t-cyan-light)`;
const LIGHT = `var(--t-gray-light)`;
const MAGENTA = `var(--t-magenta-light)`;
const SpeechBubble = styled(motion.section)`
  && {
    padding: 0.5em;
    @media (min-width: 35em) {
      padding: 0.5em;
    }
    margin: 0.75em 0;
    p {
      line-height: 1.44;
      margin: 0.5em;
      @media (min-width: 35em) {
        margin: 1em;
      }
    }
    .nick {
      float: right;
      margin: 0 1em;
      font-family: 'built_rg';
      font-size: 150%;
      letter-spacing: 0.00625em;
      &:before {
        content: '—';
        padding-right: 0.25em;
      }
    }
    &.narrator {
      display: flex;
      align-items: center;
      background: rgba(0, 0, 0, 0.35);
      color: var(--t-black);
      background: var(--t-yellow);
      box-shadow: 0 0.5em 2em black;
      width: fit-content;
      padding: 0.5em 1.5em;
      font-style: italic;
      border-radius: 0.5em;
      img {
        width: 30px;
        box-shadow: 0 0.1em 0.2em black;
      }
    }
    &:not(.narrator) {
      border-bottom: 3px ridge rgba(0, 0, 0, 0.7);
      border-radius: 0.5em;
      color: var(--t-black);
      position: relative;
      width: 40em;
      max-width: 66.6%;
      &:before {
        content: '';
        width: 0px;
        height: 0px;
        position: absolute;
        color: orange;
        font-size: 400%;
      }
    }
    &.left {
      align-self: flex-start;
      margin-left: 30px;
    }
    &.right {
      align-self: flex-end;
      margin-right: 30px;
    }
    &.light {
      background: ${LIGHT};
      &:before {
        border-left: 15px solid ${LIGHT};
        border-right: 15px solid transparent;
        border-top: 15px solid ${LIGHT};
        border-bottom: 15px solid transparent;
        right: -16px;
        top: 0px;
      }
    }
    &.cyan {
      background: ${CYAN};
      &:before {
        border-left: 15px solid transparent;
        border-right: 15px solid ${CYAN};
        border-top: 15px solid ${CYAN};
        border-bottom: 15px solid transparent;
        left: -16px;
        top: 0px;
      }
    }
    &.magenta {
      background: ${MAGENTA};
      &:before {
        border-left: 15px solid transparent;
        border-right: 15px solid ${MAGENTA};
        border-top: 15px solid ${MAGENTA};
        border-bottom: 15px solid transparent;
        left: -16px;
        top: 0px;
      }
    }
  }
`;

const StyledEndMark = styled(EndMark)`
  && {
    margin: 0 auto;
    box-sizing: content-box;
    padding: 2em;
    display: block;
    width: 100px;
  }
`;

const StyledStartMark = styled(StartMark)`
  && {
    margin: 0 auto;
    box-sizing: content-box;
    padding: 2em;
    display: block;
    width: 100px;
  }
`;

export default Kauppa;
