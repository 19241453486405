import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'

import BackgroundImage from 'gatsby-background-image'

const BG = ({ children }) => {
  const data = useStaticQuery(
    graphql`
      query {
        desktop: file(relativePath: { eq: "cards/mattituomas.png" }) {
          childImageSharp {
            fluid(quality: 90, maxWidth: 1920) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    `
  )

  const imageData = data.desktop.childImageSharp.fluid

  return (
    <BackgroundImage
      Tag="section"
      fluid={imageData}
      style={{
        backgroundSize: 'contain',
        height: '40em',
        backgroundPosition: "top right",
        width: "100%",
        display: "flex",
        alignItems: "end",
        margin: "2em 0"
      }}
    >
      {children}
    </BackgroundImage>
  )
}

export default BG