import styled from 'styled-components'

const Content = styled.section`
  && {
    max-width: 100%;
    width: 1200px;
    padding-top: 8em;
    margin: 0 auto;
    > * {
      box-sizing: border-box;
      justify-content: space-between;
    }
  }
`

export default Content